export default {
  'customer service': [
    {
      photo_url: 'https://storage.googleapis.com/komerce/LP-Advertiser/talent-cs-1.webp',
      full_name: 'Agis',
      skill_name: 'Customer Service',
      details: {
        Pengalaman: '5 Tahun 7 Bulan',
        Industri: 'Buku & Software, Kecantikan, Fashion, Serba - Serbi',
        Rate: 4.5,
      },
      rate_count: 26,
    },
    {
      photo_url: 'https://storage.googleapis.com/komerce/LP-Advertiser/talent-cs-2.webp',
      full_name: 'Nindia',
      skill_name: 'Customer Service',
      details: {
        Pengalaman: '1 Tahun 1 Bulan',
        Industri: 'Fashion',
        Rate: 4,
      },
      rate_count: 22,
    },
    {
      photo_url: 'https://storage.googleapis.com/komerce/LP-Advertiser/talent-cs-3.webp',
      full_name: 'Karina',
      skill_name: 'Customer Service',
      details: {
        Pengalaman: '3 Tahun 8 Bulan',
        Industri: 'Serba - Serbi, Fashion',
        Rate: 4,
      },
      rate_count: 18,
    },
  ],
  'admin marketplace': [
    {
      photo_url: 'https://storage.googleapis.com/komerce/LP-Advertiser/talent-am-1.webp',
      full_name: 'Bima',
      skill_name: 'Admin Marketplace',
      details: {
        Pengalaman: '4 Tahun 0 Bulan',
        Industri: 'Hobi & Olahraga, Kesehatan, Rumah Tangga, Buku & Software, Fashion',
        Rate: 4.5,
      },
      rate_count: 16,
    },
    {
      photo_url: 'https://storage.googleapis.com/komerce/LP-Advertiser/talent-am-2.webp',
      full_name: 'Mahira',
      skill_name: 'Admin Marketplace',
      details: {
        Pengalaman: '1 Tahun 11 Bulan',
        Industri: 'Fashion, Kecantikan, Elektronik, Rumah Tangga',
        Rate: 4,
      },
      rate_count: 14,
    },
    {
      photo_url: 'https://storage.googleapis.com/komerce/LP-Advertiser/talent-am-3.webp',
      full_name: 'Adami',
      skill_name: 'Admin Marketplace',
      details: {
        Pengalaman: '7 Bulan',
        Industri: 'Fashion, Buku & Software',
        Rate: 4,
      },
      rate_count: 2,
    },
  ],
  advertiser: [
    {
      photo_url: 'https://storage.googleapis.com/komerce/LP-Advertiser/talent-ads-1.webp',
      full_name: 'Rio',
      skill_name: 'Advertiser',
      details: {
        Pengalaman: '1 Tahun 3 Bulan',
        Industri: 'Kesehatan, Franchise, FnB, Serba - Serbi, Fashion',
        Rate: 4,
      },
      rate_count: 18,
    },
    {
      photo_url: 'https://storage.googleapis.com/komerce/LP-Advertiser/talent-ads-2.webp',
      full_name: 'Surip',
      skill_name: 'Advertiser',
      details: {
        Pengalaman: '2 Tahun 6 Bulan',
        Industri: 'Kesehatan, Fashion',
        Rate: 4.5,
      },
      rate_count: 14,
    },
    {
      photo_url: 'https://storage.googleapis.com/komerce/LP-Advertiser/talent-ads-3.webp',
      full_name: 'Asad',
      skill_name: 'Advertiser',
      details: {
        Pengalaman: '5 Tahun 2 Bulan',
        Industri: 'Kesehatan, Kecantikan, Fashion, Properti, Konsultan & Training',
        Rate: 4,
      },
      rate_count: 17,
    },
  ],
  'live streamer': [
    {
      photo_url: 'https://storage.googleapis.com/komerce/LP-Advertiser/talent-ls-1.webp',
      full_name: 'Aldila',
      skill_name: 'Live Streamer',
      details: {
        Pengalaman: '1 Tahun 1 Bulan',
        Industri: 'Kecantikan, Rumah Tangga, Buku & Software, Serba - Serbi',
        Rate: 4,
      },
      rate_count: 1,
    },
    {
      photo_url: 'https://storage.googleapis.com/komerce/LP-Advertiser/talent-ls-2.webp',
      full_name: 'Syavina',
      skill_name: 'Live Streamer',
      details: {
        Pengalaman: '9 Bulan',
        Industri: 'Serba - Serbi',
        Rate: 3.5,
      },
      rate_count: 8,
    },
    {
      photo_url: 'https://storage.googleapis.com/komerce/LP-Advertiser/talent-ls-3.webp',
      full_name: 'Firda',
      skill_name: 'Live Streamer',
      details: {
        Pengalaman: '1 Tahun 2 Bulan',
        Industri: 'Kecantikan, Serba - Serbi, Kesehatan',
        Rate: 4,
      },
      rate_count: 4,
    },
  ],
}
