<template>
  <b-card
    no-body
  >
    <b-row class="d-flex align-items-center mb-1 mt-2 px-2">
      <b-col>
        <h4 class="text-black">
          <strong>Akses Akun</strong>
        </h4>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <p class="text-black ml-2">
          Tambahkan orang untuk membantu mengelola usaha dan beri akses sesuai keinginan kamu
        </p>
      </b-col>
    </b-row>

    <TalentList
      :data="talents"
      :loading="talentLoad"
    />

    <b-row>
      <b-col>
        <b-overlay
          variant="light"
          :show="loading"
          spinner-variant="primary"
          blur="0"
          opacity=".5"
          rounded="sm"
        >
          <b-table
            ref="table"
            hover
            responsive
            class="position-relative"
            empty-text="Tidak ada data untuk ditampilkan."
            :show-empty="!loading"
            :fields="fields"
            :items="listMember"
            :busy.sync="loading"
          >

            <template #cell(full_name)="data">
              <h5 class="text-black">
                <strong>{{ data.item.full_name }}</strong>
              </h5>
              <p>
                {{ data.item.email }}
              </p>
            </template>

            <template #cell(access_menu)="data">
              <b-row>
                <div
                  v-for="(item, index) in data.item.access_menu"
                  :key="index+1"
                  class="mr-50"
                >
                  {{ item.menu_name }},
                </div>
              </b-row>
            </template>

            <template #cell(action)="data">
              <!-- submit and reset -->
              <b-col
                md="12"
                class="d-flex justify-content-end mt-2"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  v-b-modal.modal-primary
                  type="submit"
                  variant="primary"
                  class="mr-1"
                  @click="showModalAccessEdit(data)"
                >
                  Edit
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-primary"
                  class="mr-1"
                  @click="confirmDelete(data)"
                >
                  Hapus
                </b-button>
              </b-col>
            </template>

          </b-table>
        </b-overlay>
      </b-col>
    </b-row>

    <b-row class="mt-2 pb-2">
      <b-button
        variant="outline-primary"
        class="ml-3"
        :disabled="listMember.length >= 40"
        @click="showModal"
      >
        Tambah Orang
      </b-button>
    </b-row>

    <b-modal
      ref="modal-access-account"
      scrollable
      :title="editMode ? 'Edit Akses Akun' : 'Tambah Akses Akun'"
      ok-only
      ok-title="Simpan"
      no-close-on-backdrop
      no-close-on-esc
      cancel-variant="outline-primary"
      @shown="onModalShown"
      @hidden="onModalHidden"
      @hide="defaultAccess"
    >
      <validation-observer ref="formRules">
        <b-form>
          <b-row>

            <b-col
              cols="10"
            >
              <b-form-group
                label="Nama"
              >
                <validation-provider
                  v-if="!editMode"
                  #default="{errors}"
                  name="Nama"
                  rules="required"
                >
                  <b-form-input
                    v-model="fullname"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-primary">{{ errors[0] }}</small>
                </validation-provider>
                <b-form-input
                  v-else
                  v-model="fullname"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="10"
            >
              <b-form-group
                label="Email"
              >
                <validation-provider
                  v-if="!editMode"
                  #default="{errors}"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    v-model="emailUser"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-primary">{{ errors[0] }}</small>
                </validation-provider>
                <b-form-input
                  v-else
                  v-model="emailUser"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              v-if="!editMode"
              cols="10"
            >
              <b-form-group
                label="Password"
              >
                <validation-provider
                  v-if="!editMode"
                  #default="{errors}"
                  name="Password"
                  rules="required"
                >
                  <b-form-input
                    v-model="password"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-primary">{{ errors[0] }}</small>
                </validation-provider>
                <b-form-input
                  v-else
                  v-model="password"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <hr>
            </b-col>

            <b-col cols="12">
              <b-form-group>
                <b-form-checkbox
                  v-model="allAccess"
                  @change="setAllAccess"
                >
                  <h5>
                    <strong>Akses Website</strong>
                  </h5>
                </b-form-checkbox>

                <b-row
                  v-for="(item, index) in listAccess"
                  :key="index+1"
                  class="ml-2"
                >
                  <p class="mt-50 ml-1">
                    <strong>{{ item.label }}</strong>
                  </p>

                  <b-col
                    v-for="(children, indexChildren) in item.children"
                    :key="indexChildren+1"
                    cols="12"
                  >
                    <b-form-group>
                      <b-form-checkbox
                        v-if="children.isDisable"
                        :disabled="children.isDisable"
                      >
                        {{ children.label }}
                      </b-form-checkbox>
                      <b-form-checkbox
                        v-else
                        v-model="children.value"
                        @change="setAccess(item, children)"
                      >
                        {{ children.label }}
                      </b-form-checkbox>
                    </b-form-group>
                    <div
                      v-if="children.label.toLowerCase() === 'kendala' && isActiveTrackingSales"
                    >
                      <p class="font-semibold">
                        Terjual Oleh
                      </p>
                      <div
                        v-for="grandChildren in children.sales"
                        :key="grandChildren.id"
                      >
                        <b-form-group>
                          <b-form-checkbox v-model="grandChildren.isChecked">
                            {{ grandChildren.name }}
                          </b-form-checkbox>
                        </b-form-group>
                      </div>
                    </div>
                  </b-col>
                </b-row>

              </b-form-group>
            </b-col>

          </b-row>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <b-row class="align-items-center w-100">
          <b-col
            cols="12"
            class="d-flex justify-content-end"
          >
            <b-button
              ref="submitBtn"
              variant="primary"
              :class="loadingSubmit ? 'cursor-not-allowed' : ''"
              :disabled="loadingSubmit"
              @click="() => editMode ? submitAccountUpdate() : submitAccount()"
            >
              <b-spinner
                v-if="loadingSubmit"
                variant="light"
                small
              />
              {{ editMode ? 'Update' : 'Simpan' }}
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>

    <ModalConfirm
      id="modal-verifikasi-ktp"
      title="Yuk Verifikasi Identitas Kamu"
      message="Verifikasi diperlukan untuk memvalidasi keaslian akun kamu"
      label-confirm-button="Ajukan Verifikasi"
      label-cancel-button="Batal"
      @on-click-cancel-button="$router.go(-1)"
      @on-click-confirm-button="$router.push({ name: 'verification-ktp'})"
    />
    <ModalConfirm
      id="modal-waiting-verify"
      title="Verifikasi Identitas Sedang Direview"
      message="Maaf, saat ini aktivitas tidak dapat dilanjutkan, mohon tunggu sampai proses verifikasi selesai"
    >
      <template #footer>
        <div class="w-full">
          <b-button
            variant="primary"
            class="rounded-[8px!important]"
            block
            @click="$router.go(-1)"
          >Oke</b-button>
        </div>
      </template>
    </ModalConfirm>
    <ModalConfirm
      id="modal-error-api"
      title="Oops, sistem bermasalah!"
      message="Sedang terjadi masalah pada sistem kami, silahkan coba kembali beberapa saat lagi"
    >
      <template #footer>
        <div class="w-full">
          <b-button
            variant="primary"
            class="rounded-[8px!important]"
            block
            @click="$router.go(-1)"
          >Oke</b-button>
        </div>
      </template>
    </ModalConfirm>

  </b-card>
</template>

<script>
import {
  BCol,
  BRow,
  BCard,
  BTable,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BOverlay,
  BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required, email } from '@validations'
import secureLs from '@/libs/secureLs'
import { newAxiosIns } from '@/libs/axios'
import ModalConfirm from '@/views/components/modal/ModalConfirm.vue'
import { mapState } from 'vuex'
import TalentList from './Komtim/TalentList.vue'
import TALENT_DUMMY from './talent_dummy'

export default {
  components: {
    BCol,
    BRow,
    BCard,
    BTable,
    BButton,
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BOverlay,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    ModalConfirm,
    TalentList,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      loading: false,
      loadingSubmit: false,

      listAccess: [
        {
          label: 'Dashboard',
          resLabel: 'DASHBOARD',
          children: [{
            value: false,
            label: 'Dashboard',
            access: [1, 2, 3, 4],
            isDisable: false,
          }],
        },
        {
          label: 'Produk',
          resLabel: 'PRODUCT',
          children: [
            {
              label: 'Tambah Produk',
              value: false,
              access: [2, 3, 4],
              isDisable: false,
            },
            {
              label: 'Data Produk',
              value: false,
              access: [1],
              isDisable: false,
            },
          ],
        },
        {
          label: 'Gudang',
          resLabel: 'GUDANG',
          children: [
            {
              value: false,
              label: 'Cari Gudang',
              access: [18],
              isDisable: false,
            },
            {
              value: false,
              label: 'Gudangku',
              access: [16],
              isDisable: false,
            },
            {
              value: false,
              label: 'Ajukan Inbound',
              access: [17],
              isDisable: false,
            },
            {
              value: false,
              label: 'Riwayat Pengajuan',
              access: [19],
              isDisable: false,
            },
          ],
        },
        {
          label: 'Orderan',
          resLabel: 'ORDER',
          children: [
            {
              label: 'Tambah Order',
              value: false,
              access: [2, 3, 4],
              isDisable: false,
            },
            {
              label: 'Data Order',
              value: false,
              access: [1],
              isDisable: false,
            },
          ],
        },
        {
          label: 'Pickup',
          resLabel: 'PICKUP',
          children: [
            {
              label: 'Ajukan Pickup',
              value: false,
              access: [5],
              isDisable: false,
            },
            {
              label: 'Riwayat Pickup',
              value: false,
              access: [6],
              isDisable: false,
            },
          ],
        },
        {
          label: 'Keuangan',
          resLabel: 'FINANCE',
          children: [
            {
              label: 'Penghasilan',
              value: false,
              access: [7],
              isDisable: false,
            },
            {
              label: 'Riwayat Penarikan',
              value: false,
              access: [8],
              isDisable: false,
            },
          ],
        },
        {
          label: 'Pengaturan',
          resLabel: 'SETTING',
          children: [
            {
              label: 'Ekspedisi',
              value: false,
              access: [14],
              isDisable: false,
            },
          ],
        },
        {
          label: 'Kendala',
          resLabel: 'KENDALA',
          children: [{
            value: false,
            label: 'Kendala',
            access: [1, 2, 3, 4],
            isDisable: false,
            sales: [],
          }],
        },
      ],

      fields: [
        {
          key: 'full_name',
          label: 'Nama',
          thClass: 'bg-white border-top-0 font-bold',
          class: 'text-black',
        },
        {
          key: 'access_menu',
          label: 'Akses',
          thClass: 'bg-white border-top-0 font-bold',
          class: 'text-black',
        },
        {
          key: 'action',
          label: '',
          thClass: 'bg-white border-top-0 font-bold',
          class: 'text-black',
        },
      ],

      name: '',
      username: '',
      password: '',
      fullname: '',
      emailUser: '',
      partnerId: secureLs.getItem('userData'),

      editMode: false,
      menuAksesEdit: [],
      idEdit: '',

      // Validation
      required,
      email,

      maxAccessAccount: false,
      listMember: [],
      menuStore: [],
      menuMemberList: [],
      listAccessEdit: [],
      idUpdateAccount: null,

      allAccess: false,
      modalIsOpen: false,
      talentLoad: true,
      talents: [],
    }
  },
  computed: {
    ...mapState('dashboard', ['profile', 'saldo']),
    salesPayload() {
      const indexKendala = this.listAccess.findIndex(access => access.label.toLowerCase() === 'kendala')

      if (indexKendala !== -1) {
        const create = []
        const update = []

        this.listAccess[indexKendala].children.forEach(child => {
          create.push(...(child.sales.filter(item => item.isChecked) || []).map(item => ({ tracking_sales_id: item.id })))
          update.push(...(child.sales.map(item => ({ tracking_sales_id: item.id, is_checked: item.isChecked })) || []))
        })

        return { create, update }
      }

      return { create: [], update: [] }
    },
    isActiveTrackingSales() {
      return this.$store.state.dashboard.profile.partner_is_tracking_sales
    },
  },
  async mounted() {
    await this.getTalent()
    if (this.profile.verification_ktp_status === 1) {
      this.$bvModal.show('modal-waiting-verify')
    } else if (this.profile.verification_ktp_status !== 2) {
      this.verificationKtp()
    } else {
      this.fetchAccessList()
      if (this.isActiveTrackingSales) {
        this.getTrackingSales()
      }
      this.fetchMemberData()
      this.filterListAccess()
    }
    window.addEventListener('keydown', this.onSubmit)
  },
  destroyed() {
    window.removeEventListener('keydown', this.onSubmit)
  },
  methods: {
    onModalShown() {
      setTimeout(() => { this.modalIsOpen = true }, 1000)
    },
    onModalHidden() {
      this.modalIsOpen = false
    },
    onSubmit({ key }) {
      if (key !== 'Enter') return
      this.$refs.submitBtn.click()
    },
    fetchAccessList() {
      this.$http.get('/user/partner/get-menu-member')
        .then(response => {
          this.menuMemberList = response.data.data
        })
    },
    fetchMemberData() {
      this.$http.get(`/user/partner/get-komship-member/${this.partnerId.partner_detail.id}`).then(response => {
        const { data } = response.data
        this.listMember = data
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Gagal',
            icon: 'AlertCircleIcon',
            text: 'Gagal me-load data, silahkan coba lagi',
            variant: 'danger',
          },
        }, 2000)
      })
    },
    filterListAccess() {
      const { is_komship, is_kompack } = this.partnerId

      if (is_komship === 1 && is_kompack === 0) {
        this.listAccess = this.listAccess.map(item => (item.label === 'Gudang'
          ? { ...item, children: item.children.filter(child => child.label === 'Gudangku') }
          : item))
      } else if (is_komship === 0 && is_kompack === 1) {
        this.listAccess = this.listAccess
          .map(item => {
            if (['Dashboard', 'Orderan', 'Kendala', 'Pengaturan'].includes(item.label)) {
              return null
            }
            if (item.label === 'Keuangan') {
              return { ...item, children: item.children.filter(child => child.label === 'Riwayat Penarikan') }
            }
            return item
          })
          .filter(item => item !== null)
      }
    },
    showModal() {
      this.editMode = false
      this.listAccess.forEach(item => {
        item.children.forEach(childItem => {
          // eslint-disable-next-line no-param-reassign
          childItem.value = false
        })
      })
      this.toggleAllCustomer(true)
      this.menuStore = []
      this.emailUser = ''
      this.password = ''
      this.fullname = ''
      this.$refs['modal-access-account'].show()
    },
    setAccess(data, dataChildren) {
      const findMenu = this.menuMemberList.find(item => item.name === data.resLabel)
      if (dataChildren.value === true) {
        if (findMenu !== undefined) {
          dataChildren.access.forEach(childItem => {
            this.menuStore.push({
              menu_position_id: findMenu.menu_position[0].id,
              menu_access_id: childItem,
            })
          })
        }
      } else {
        // eslint-disable-next-line no-plusplus
        for (let x = 0; x < dataChildren.access.length; x++) {
          const findMenuStoreIndex = this.menuStore.findIndex(indexItem => indexItem.menu_position_id === findMenu.menu_position[0].id && dataChildren.access[x] === indexItem.menu_access_id)
          this.menuStore.splice(findMenuStoreIndex, 1)
        }
      }
    },
    submitAccount() {
      this.loadingSubmit = true
      this.$refs.formRules.validate().then(success => {
        if (success) {
          const body = {
            email: this.emailUser,
            password: this.password,
            full_name: this.fullname,
            partner_id: this.partnerId.partner_detail.id,
            menu: this.menuStore,
          }

          if (this.isActiveTrackingSales) {
            body.sold_by = this.salesPayload.create
          }

          this.$http.post('/v2/user/partner/create-account', body)
            .then(response => {
              const { data } = response
              if (data.code === 400) {
                this.loadingSubmit = false
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Failed',
                    icon: 'AlertCircleIcon',
                    text: 'Username or Email has already taken!',
                    variant: 'danger',
                  },
                }, 2000)
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Success',
                    icon: 'CheckIcon',
                    text: 'Success menambahkan akun',
                    variant: 'success',
                  },
                }, 2000)
                this.loadingSubmit = false
                this.$refs['modal-access-account'].hide()
                this.fetchMemberData()
              }
              this.loadingSubmit = false
            }).catch(err => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Failure',
                  icon: 'AlertCircleIcon',
                  text: err,
                  variant: 'danger',
                },
              }, 2000)
              this.loadingSubmit = false
            })
        } else {
          this.loadingSubmit = false
        }
      })
    },
    showModalAccessEdit(data) {
      this.menuStore = []
      this.fullname = data.item.full_name
      this.password = data.item.password
      this.emailUser = data.item.email
      this.editMode = true
      this.$refs['modal-access-account'].show()
      this.idUpdateAccount = data.item.user_id
      this.listAccessEdit = []
      this.$http.get(`/v2/user/partner/get-menu-komship-member/${data.item.user_id}`).then(response => {
        if (this.isActiveTrackingSales) {
          const sales = response.data.data.sold_by

          if (Array.isArray(sales)) {
            this.setTrackingSales(sales.map(item => ({ ...item, isChecked: item.is_checked })))
          }
        }
        const filterAccess = response.data.data.privilege.filter(item => item.access !== 'No Data Access.')
        const findListAccess = this.listAccess.map(item => ({
          filter: filterAccess.find(filterItem => item.resLabel === filterItem.menu_name),
        }))
        findListAccess.forEach(item => {
          if (item.filter !== undefined) this.listAccessEdit.push(item.filter)
        })
        this.listAccessEdit.forEach(item => {
          item.access.forEach(filAccessItem => {
            this.listAccess.forEach(listItem => {
              listItem.children.forEach(childItem => {
                if (listItem.resLabel === item.menu_name) {
                  if (childItem.access.includes(filAccessItem.access_id)) {
                    // eslint-disable-next-line no-param-reassign
                    childItem.value = true
                  }
                }
              })
            })
            const checkMenuStore = this.menuStore.find(itemStore => itemStore.menu_position_id === filAccessItem.menu_position_id && itemStore.menu_access_id === filAccessItem.access_id)
            if (checkMenuStore === undefined) {
              this.menuStore.push({
                menu_position_id: filAccessItem.menu_position_id,
                menu_access_id: filAccessItem.access_id,
              })
            }
          })
        })
      })
    },
    submitAccountUpdate() {
      this.loadingSubmit = true
      this.$refs.formRules.validate().then(success => {
        if (success) {
          const body = {
            user_id: this.idUpdateAccount,
            menu: this.menuStore,
          }

          if (this.isActiveTrackingSales) {
            body.sold_by = this.salesPayload.update
          }

          this.$http.put(`/v2/user/partner/update-account/${this.idUpdateAccount}`, body).then(response => {
            const { data } = response
            if (data.code === 400) {
              this.loadingSubmit = false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Failed',
                  icon: 'AlertCircleIcon',
                  text: 'Username or Email has already taken!',
                  variant: 'danger',
                },
              }, 2000)
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Success',
                  icon: 'CheckIcon',
                  text: 'Akun berhasil di update',
                  variant: 'success',
                },
              })
              this.loadingSubmit = false
              this.$refs['modal-access-account'].hide()
              this.fetchMemberData()
            }
          }).catch(err => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text: err,
                variant: 'danger',
              },
            }, 2000)
            this.loadingSubmit = false
          })
        } else {
          this.loadingSubmit = false
        }
      })
    },
    confirmDelete(data) {
      this.$swal({
        text: 'Kamu yakin ingin hapus akun ?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Batal',
        confirmButtonText: 'Hapus',
        customClass: {
          confirmButton: 'btn btn-outline-primary',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.delete(data)
        }
      })
    },
    delete(data) {
      this.$http.delete(`/user/partner/delete-komship-member/${data.item.user_id}`)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'Success delete access',
              variant: 'success',
            },
          }, 2000)
          this.fetchMemberData()
        }).catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Gagal',
              icon: 'AlertCircleIcon',
              text: 'Gagal hapus akses, silahkan coba lagi!',
              variant: 'danger',
            },
          }, 2000)
        })
    },
    toggleAllCustomer(nextValue = false) {
      const indexKendala = this.listAccess.findIndex(access => access.label.toLowerCase() === 'kendala')

      if (indexKendala !== -1) {
        this.listAccess[indexKendala].children = this.listAccess[indexKendala].children.map(child => ({ ...child, sales: child.sales.map(itemSales => ({ ...itemSales, isChecked: nextValue })) }))
      }
    },
    setAllAccess() {
      this.menuStore = []
      if (this.allAccess === true) {
        this.listAccess.forEach(item => {
          item.children.forEach(childItem => {
            // eslint-disable-next-line no-param-reassign
            childItem.value = true
            this.setAccess(item, childItem)
          })
        })

        if (this.isActiveTrackingSales) this.toggleAllCustomer(true)
      } else {
        this.listAccess.forEach(item => {
          item.children.forEach(childItem => {
            // eslint-disable-next-line no-param-reassign
            childItem.value = false
          })
        })
        this.menuStore = []

        if (this.isActiveTrackingSales) this.toggleAllCustomer(false)
      }
    },
    defaultAccess() {
      this.listAccess.forEach(item => {
        item.children.forEach(childItem => {
          // eslint-disable-next-line no-param-reassign
          childItem.value = false
        })
      })
    },
    setTrackingSales(sales = []) {
      this.listAccess = this.listAccess.map(access => {
        if (access.label.toLowerCase() === 'kendala') {
          return { ...access, children: access.children.map(item => ({ ...item, sales })) }
        }

        return access
      })
    },
    getTrackingSales() {
      this.$http_komship.get(`/v1/partners/${this.partnerId.partner_detail.id}/customer-services`)
        .then(res => {
          const sales = (res.data.data || []).map(item => ({ ...item, isChecked: true }))
          this.setTrackingSales(sales)
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.response.data.message,
              variant: 'danger',
            },
          }, 2000)
        })
    },
    async verificationKtp() {
      await this.$http_new.get('/komship/api/v1/pickup/verification-check')
        .then(async response => {
          const { data } = response.data
          if (data.never_pickup) {
            this.$bvModal.show('modal-verifikasi-ktp')
          } else {
            this.fetchAccessList()
            if (this.isActiveTrackingSales) {
              this.getTrackingSales()
            }
            this.fetchMemberData()
            this.filterListAccess()
          }
        })
        .catch(err => {
          this.$toast_error({ message: 'Gagal Load Data' })
          this.$bvModal.show('modal-error-api')
        })
    },
    talentMap(data) {
      return data.map(d => {
        if (d.details) { return d }
        const result = d
        result.details = {
          Pengalaman: d.experience,
          Industri: d.industry_name,
          'Closing Rate': d.closing_rate,
          Platform: d.platform_name,
          Rate: d.rate,
        }
        return result
      })
    },
    async getTalent() {
      try {
        const role = ['customer service', 'admin marketplace', 'advertiser', 'live streamer']
        const res = await Promise.all(role.map(r => newAxiosIns.get(`/talent-pool/api/v1/resource/talents?limit=2&rating=4,5&skill_name=${r}`)))
        this.talents = res.map(({ data: { data } }, i) => {
          if (!data || data.length === 0) { return TALENT_DUMMY[role[i]] }
          return this.talentMap(data.map((d, j) => (d.photo_url === '' ? TALENT_DUMMY[role[i]][j] : d)))
        }).flat()
      } catch (err) {
        this.$toast_error({ message: 'Gagal Load Data' })
      } finally {
        this.talentLoad = false
      }
    },
  },

}
</script>
