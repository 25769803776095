<template>
  <div class="flex items-center gap-[4px]">
    <svg
      v-for="_, index in 5"
      :key="index"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="16"
      height="16"
      class="star"
    >
      <defs>
        <linearGradient
          :id="`gradient-${index}-${id}`"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
        >
          <stop
            :offset="Math.min(1, Math.max(0, rate - index)) * 100 + '%'"
            stop-color="#FBBC05"
          />
          <stop
            :offset="Math.min(1, Math.max(0, rate - index)) * 100 + '%'"
            stop-color="#FFFFFF"
          />
        </linearGradient>
      </defs>
      <path
        d="M12 3c.5 0 1 .3 1.2.8l2 4 4.5.6c.5.1.9.4 1.1.9.2.5.1 1.1-.2 1.5l-3.3 3.2.8 4.7c.1.5-.2 1-.6 1.3-.4.3-.9.4-1.4.1L12 17.6l-4.1 2.2c-.5.3-1.1.2-1.4-.1-.4-.3-.7-.8-.6-1.3l.8-4.7-3.3-3.2c-.4-.4-.5-1-.2-1.5.2-.5.6-.8 1.1-.9l4.5-.6 2-4c.2-.5.7-.8 1.2-.8z"
        :fill="`url(#gradient-${index}-${id})`"
        style="stroke: #FBBC05; stroke-width: 1.5; stroke-linejoin: round;"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      default: 0,
    },
    rate: {
      type: Number,
      required: true,
      default: 0,
    },
  },
}
</script>
