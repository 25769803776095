<template>
  <div
    id="talent-pool"
    style="background: linear-gradient(180deg, #FFFFFF 0%, #EEFFF3 20.17%, #D6EEDD 89.17%);"
    class="pb-[32px] my-[20px] [&_p]:mb-0 border-t border-[#E2E2E2] sm:pb-[12px] pt-[40px] relative [&_.arrow]:min-w-[37px] [&_.arrow]:h-[37px] [&_.arrow]:rounded-full
    [&_.arrow]:bg-white [&_.arrow]:shadow-[0_10px_8px_0_#00832D29] [&_.arrow]:grid [&_.arrow]:place-items-center [&_.arrow]:border-none [&_.arrow]:outline-none transition
    duration-300 disabled:[&_.arrow]:opacity-[.25] active:[&_.arrow:not(:disabled)]:bg-[#D6EEDD] [&_.arrow.desk]:hidden sm:[&_.arrow.desk]:block [&_.arrow:not(.desk)]:block
    sm:[&_.arrow:not(.desk)]:hidden [&_.arrow:not(:disabled)]:transition-colors [&_.arrow:not(:disabled)]:duration-300"
  >
    <div class="pl-[calc(1rem+1vw)] flex items-center gap-[18px]">
      <button
        aria-label="arrow-left-desk"
        class="arrow desk left [&:not(:disabled)>*]:hover:-translate-x-[2px] [&:not(:disabled)>*]:transition-transform [&:not(:disabled)>*]:duration-300"
        :disabled="disableLeftPoolArrow"
        @click="back"
      >
        <img
          src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-left-2.svg"
          width="24"
          height="24"
          alt="arrow-left-desk"
          loading="lazy"
        >
      </button>
      <div class="flex flex-col lg:flex-row lg:items-center gap-[24px] w-full overflow-hidden text-left relative">
        <div class="z-[2] relative w-[calc(100%-25px)] sm:w-[calc(100%-87px)] lg:w-full lg:max-w-[248px]">
          <strong class="text-[20px] font-bold leading-[23px] sm:leading-[28px] tracking-[.5px] text-[#333333]">Talent Pool <span class="text-[#34A853]">Komtim</span></strong>
          <p class="mt-[18px] !mb-[20px] text-[14px] leading-[21px] tracking-[.5px] text-[#333333]">
            Jelajahi Talent Pool Komtim dan pilih talenta unggulan yang siap mendukung kesuksesan bisnismu.
          </p>
          <a
            href="https://komtim.id/talent-pool"
            target="_blank"
            rel="noopener noreferrer"
            class="font-semibold text-[14px] bg-white text-[#34A853] hover:text-[#10673E] active:bg-white/80 h-[32px] px-[10px] whitespace-nowrap rounded-[4px] grid place-items-center w-fit transition-colors duration-300"
          >Rekrut Talent Sekarang</a>
        </div>
        <div
          class="w-full overflow-hidden select-none cursor-grab"
          :style="{cursor: `grab${grab? 'bing' : ''}`, pointerEvents: loading ? 'none' : 'auto'}"
          @touchstart.passive="startSlide($event, { mobile: true })"
          @touchend="endSlide($event, { mobile: true })"
          @mousedown="startSlide($event)"
          @mouseup="endSlide($event)"
          @touchmove.passive="moveSlide($event, { mobile: true })"
          @mousemove="grab ? moveSlide($event) : false"
        >
          <div
            class="min-w-full flex gap-[16px] transition-transform duration-300 will-change-transform"
            :style="{transform: `translateX(calc(${movement}px - ${(screen >= 640 ? 336 : 266) * currentPoolSlide}px))`}"
          >
            <template v-if="loading">
              <PoolCardSkeleton
                v-for="i in 3"
                :key="i"
                class="min-w-[250px] sm:min-w-[320px] rounded-[16px] p-[18px] sm:p-[24px] bg-white shadow-[0_0_16px_0_#00832D0D] flex flex-col gap-[24px] justify-between flex-[1]"
              />
            </template>
            <template v-else>
              <PoolCard
                v-for="pool, i in data"
                :id="pool.id || i"
                :key="i"
                class="min-w-[250px] sm:min-w-[320px] rounded-[16px] p-[18px] sm:p-[24px] bg-white shadow-[0_0_16px_0_#00832D0D] flex flex-col gap-[24px] justify-between flex-[1]"
                :image="pool.photo_url"
                :name="pool.full_name"
                :role="pool.skill_name"
                :rate-count="pool.rate_count"
                :details="pool.details"
              />
            </template>
          </div>
        </div>
        <button
          aria-label="arrow-right-desk"
          class="arrow desk right [&:not(:disabled)>*]:hover:translate-x-[2px] [&:not(:disabled)>*]:transition-transform [&:not(:disabled)>*]:duration-300 absolute right-[calc(1rem+1vw)] top-1/2 -translate-y-[18.5px] z-[2]"
          :disabled="disableRightPoolArrow"
          @click="next"
        >
          <img
            src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-right-2.svg"
            alt="arrow-right"
            height="24"
            width="24"
            loading="lazy"
          >
        </button>
      </div>
      <div
        class="absolute inset-y-0 right-0 z-[1] pointer-events-none w-[0] sm:w-[240px]"
        style="background: linear-gradient(90.63deg, rgba(255, 255, 255, 0) 0.41%, rgba(215, 239, 222, 0.9) 67.69%);"
      />
    </div>
    <div class="w-full flex items-center justify-center gap-[10px] mt-[24px] px-[calc(1rem+1vw)]">
      <button
        aria-label="arrow-left"
        class="arrow left [&:not(:disabled)>*]:hover:-translate-x-[2px] [&:not(:disabled)>*]:transition-transform [&:not(:disabled)>*]:duration-300"
        :disabled="disableLeftPoolArrow"
        @click="back"
      >
        <img
          src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-left-2.svg"
          alt="arrow-left"
          width="24"
          height="24"
          loading="lazy"
        >
      </button>
      <div
        v-if="!loading"
        class="flex flex-wrap items-center justify-center gap-[10px]"
      >
        <label
          v-for="i in data.length"
          :key="i"
          :for="`pool-slide-${i}`"
          class="h-[12px] w-[12px] bg-white rounded-[6px] transition-all duration-300 cursor-pointer hover:!bg-[#34A853] has-[:checked]:!bg-[#34A853] has-[:checked]:pointers-event-none has-[:checked]:w-[76px]"
        >
          <input
            :id="`pool-slide-${i}`"
            type="radio"
            style="display: none;"
            name="pool-slide"
            :checked="currentPoolSlide == (+i - 1)"
            @change="() => currentPoolSlide = (+i - 1)"
          >
        </label>
      </div>
      <button
        aria-label="arrow-right"
        class="arrow right [&:not(:disabled)>*]:hover:translate-x-[2px] [&:not(:disabled)>*]:transition-transform [&:not(:disabled)>*]:duration-300"
        :disabled="disableRightPoolArrow"
        @click="next"
      >
        <img
          src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-right-2.svg"
          alt="arrow-right"
          height="24"
          width="24"
          loading="lazy"
        >
      </button>
    </div>
  </div>
</template>

<script>
import PoolCard from './PoolCard.vue'
import PoolCardSkeleton from './PoolCardSkeleton.vue'

export default {
  components: { PoolCard, PoolCardSkeleton },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      screen: 0,
      currentPoolSlide: 0,
      xStart: 0,
      xEnd: 0,
      movement: 0,
      grab: false,
    }
  },
  computed: {
    disableLeftPoolArrow() {
      return this.currentPoolSlide === 0 || this.data.length === 0 || this.loading
    },
    disableRightPoolArrow() {
      return this.currentPoolSlide === this.data.length - 1 || this.data.length === 0 || this.loading
    },
  },
  mounted() {
    this.initPoolNeeds()
    window.addEventListener('resize', this.initPoolNeeds)
  },
  destroyed() {
    window.removeEventListener('resize', this.initPoolNeeds)
  },
  methods: {
    slider(e, mobile = false, axis = 'X') {
      return mobile ? e.changedTouches[0][`page${axis}`] : e[`page${axis}`]
    },
    initPoolNeeds() {
      this.screen = window.innerWidth
    },
    startSlide(e, param = { mobile: false }) {
      this.xStart = this.slider(e, param.mobile)
      this.grab = true
    },
    moveSlide(e, param = { mobile: false }) {
      const diff = this.slider(e, param.mobile) - this.xStart
      this.movement = param.mobile && Math.abs(diff) <= 24 ? 0 : diff
      if (this.currentPoolSlide === 0 && this.movement > 0) { this.currentPoolSlide = 0 }
      if (this.currentPoolSlide === this.data.length - 1 && this.movement < 0) { this.currentPoolSlide = this.data.length - 1 }
    },
    next() {
      if (!this.disableRightPoolArrow) {
        const current = this.currentPoolSlide
        this.currentPoolSlide = current + 1
        console.log(current, this.currentPoolSlide)
      }
    },
    back() {
      if (!this.disableLeftPoolArrow) {
        const current = this.currentPoolSlide
        this.currentPoolSlide = current - 1
        console.log(current, this.currentPoolSlide)
      }
    },
    endSlide(e, param = { mobile: false }) {
      this.grab = false
      this.xEnd = this.slider(e, param.mobile)
      if (this.xStart > this.xEnd && (this.xStart - this.xEnd > 92)) { this.next() }
      if (this.xStart < this.xEnd && (this.xEnd - this.xStart > 92)) { this.back() }
      this.movement = 0
    },
  },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

#talent-pool, #talent-pool *, #talent-pool >>> * {
    font-family: 'Plus Jakarta Sans', sans-serif;
}
.arrow > * {
    filter: brightness(0) saturate(100%) invert(62%) sepia(25%) saturate(1177%) hue-rotate(84deg) brightness(84%) contrast(84%);
}
</style>
