<template>
  <div
    class="[&_p]:mb-0"
    :style="outline !== '' ? `border: 1px solid ${outline}` : ''"
  >
    <div>
      <div class="w-full flex gap-[10px] justify-between">
        <div class="w-full flex items-center gap-[12px]">
          <div class="w-[44px] h-[44px] rounded-full overflow-hidden grid place-items-center bg-[#e5e5e5]">
            <img
              v-if="validImage.length > 0"
              :src="image"
              alt="thumb"
              width="48"
              height="48"
              loading="lazy"
              class="object-cover object-top"
            >
            <span
              v-else
              class="k-user text-[24px]"
            />
          </div>
          <div class="w-[calc(100%-60px)]">
            <p class="capitalize text-[18px] leading-[25.2px] font-semibold text-[#333333] truncate w-full">
              {{ name.toLowerCase() }}
            </p>
            <p class="mt-[2px] text-[12px] leading-[17.6px] w-full truncate text-[#34A853]">
              {{ role }}
            </p>
          </div>
        </div>
      </div>
      <div class="flex flex-col gap-[6px] text-[12px] mt-[16px]">
        <template v-for="key, j in Object.keys(cleanDetails)">
          <div
            v-if="cleanDetails[key]"
            :key="`${j}`"
            class="flex"
          >
            <span class="min-w-[92px] text-[#828282]">{{ key }}</span>
            <div class="w-full text-[#333333] flex items-center justify-between gap-[12px]">
              <RatingStars
                v-if="key == 'Rate'"
                :id="id"
                :rate="cleanDetails[key]"
              />
              <span :class="{'rate-count': j == 4}">{{ j == 4 ? `(${rateCount})` : cleanDetails[key] }}</span>
            </div>
          </div>
        </template>
      </div>
    </div>
    <a
      class="w-full h-[36px] grid place-items-center px-[10px] bg-white text-[#34A853] hover:!bg-[#34A853] hover:!text-white border rounded-[8px] text-[14px] font-semibold outline-none [transition:_background-color_.3s,_letter-spacing_.3s]"
      style="border: 1px solid #34A853 !important;"
      href="https://komtim.id/talent-pool"
      target="_blank"
      rel="noopener noreferrer"
    >
      Lihat Detail
    </a>
  </div>
</template>

<script>
import RatingStars from './RatingStars.vue'

export default {
  components: { RatingStars },
  props: {
    id: {
      type: Number,
      default: 0,
    },
    image: {
      type: String,
      // eslint-disable-next-line global-require
      default: require('@/assets/images/avatars/1.png'),
    },
    name: {
      type: String,
      default: 'Gina',
    },
    role: {
      type: String,
      default: 'Customer Service',
    },
    rateCount: {
      type: Number,
      default: 15,
    },
    details: {
      type: Object,
      default: () => ({
        Pengalaman: '4 Tahun 10 Bulan',
        Industri: 'Fashion, Kesehatan, Kecantikan',
        'Closing Rate': '50%',
        Platform: '',
        Rate: 4.5,
      }),
    },
    actionClass: {
      type: String,
      default: '',
    },
    actionStyle: {
      type: String || Object,
      default: '',
    },
    outline: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      validImage: '',
    }
  },
  computed: {
    cleanDetails() {
      return {
        Pengalaman: !this.details.Pengalaman || this.details.Pengalaman === '0 bulan' ? 'Belum tersedia' : this.details.Pengalaman,
        Industri: this.details.Industri || 'Belum tersedia',
        'Closing Rate': this.details['Closing Rate'] > 0 && this.role.toLowerCase() === 'customer service' ? `${this.details['Closing Rate']}%` : null,
        Platform: this.details.Platform && this.role.toLowerCase() === 'admin marketplace' ? this.details.Platform : null,
        Rate: this.details.Rate > 0 ? this.details.Rate : null,
      }
    },
  },
  watch: {
    async image(value) {
      this.validImage = await this.checkImage(value)
    },
  },
  async mounted() {
    this.validImage = await this.checkImage(this.image)
  },
  methods: {
    checkImage(url) {
      return new Promise(resolve => {
        const img = new Image()
        img.src = url
        img.onload = () => resolve(url)
        img.onerror = () => resolve('')
      })
    },
  },
}
</script>
